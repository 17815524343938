import React, { useState, useRef } from "react"
import { Container, Row, Col } from "react-awesome-styled-grid"
import { navigate } from "gatsby"

import regex from "../../../../helpers/regex"
import { setUploadImages, setFormData } from "../../../../middleware"
import CoverForm from "../../coverForm/CoverForm"
import UploadImages from "../../uploadImages/UploadImages"
import InputText from "../../../elements/inputText/InputText"
import Checkbox from "../../../elements/checkBox/CheckBox"
import ButtonText, { ButtonTextType } from "../../../elements/buttonText/ButtonText"
import InputContentEditable from "../../../elements/inputContentEditable/InputContentEditable"
import InputError from "../../../elements/inputError/InputError"
import TermsPopUp from "../../../elements/termsPopUp/termsPopUp"
import { FormContainer, Divisor } from "./FormImage.style"

const FormImage = ({ option, setOption }) => {
  const classNameID = "form--image"
  const [isOpen, setIsOpen] = useState(false)

  const coverFile = useRef()
  const inputTitle = useRef()
  const inputName = useRef()
  const inputPlace = useRef()
  const inputDescription = useRef()
  const inputEmail = useRef()
  const inputImages = useRef()
  const checkboxTerms = useRef()
  const checkboxAuthorize = useRef()
  const checkboxCopyright = useRef()

  const [cover, setCover] = useState({ value: null, isValid: false })
  const [title, setTitle] = useState({ value: null, isValid: false })
  const [name, setName] = useState({ value: null, isValid: false })
  const [state, setState] = useState({
    value: null,
    isValid: false,
  })
  const [email, setEmail] = useState({ value: null, isValid: false })
  const [description, setDescription] = useState({
    value: null,
    isValid: false,
  })
  const [images, setImages] = useState([])
  const [terms, setTerms] = useState(false)
  const [authorize, setAuthorize] = useState(false)
  const [copyright, setCopyright] = useState(false)

  const [sendData, setSendData] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)
  const [errorMessage, setErrorMessage] = useState("Verifica que toda la información este completa.")

  function chainError(err) {
    return Promise.reject(err)
  }

  const handleSubmit = e => {
    e.preventDefault()

    setSendData(true)
    // console.log('description', description);
    if (cover.isValid && title.isValid && name.isValid && state.isValid && email.isValid && description.isValid && images.length > 0 && terms && authorize && copyright) {
      const promiseOne = setUploadImages(cover.value)
      const promiseTwo = setUploadImages(images[0].url)
      const promiseThree = setUploadImages(images[1] ? images[1].url : "")
      const promiseFour = setUploadImages(images[2] ? images[2].url : "")
      const promiseFive = setUploadImages(images[3] ? images[3].url : "")
      setHasErrors(false)

      let newArray = []

      promiseOne
        .then(resolve => {
          if (resolve.error !== undefined && resolve.error === 1) {
            setErrorMessage(resolve.data.message)
            setSendData(false)
            setHasErrors(true)
            throw new Error("error")
          }
          newArray = [...newArray, resolve.data.filename_disk]
          return promiseTwo
        }, chainError)

        .then(resolve => {
          if (resolve.error !== undefined && resolve.error === 1) {
            setErrorMessage(resolve.data.message)
            setSendData(false)
            setHasErrors(true)
            throw new Error("error")
          }
          newArray = [...newArray, resolve.data.filename_disk]
          return promiseThree
        }, chainError)
        .then(resolve => {
          if (resolve !== undefined) {
            if (resolve.error !== undefined && resolve.error === 1) {
              setErrorMessage(resolve.data.message)
              setSendData(false)
              setHasErrors(true)
              throw new Error("error")
            }
            newArray = [...newArray, resolve.data.filename_disk]
          }
          return promiseFour
        })

        .then(resolve => {
          if (resolve !== undefined) {
            if (resolve.error !== undefined && resolve.error === 1) {
              setErrorMessage(resolve.data.message)
              setSendData(false)
              setHasErrors(true)
              throw new Error("error")
            }
            newArray = [...newArray, resolve.data.filename_disk]
          }
          return promiseFive
        })

        .then(resolve => {
          if (resolve !== undefined) {
            if (resolve.error !== undefined && resolve.error === 1) {
              setErrorMessage(resolve.data.message)
              setSendData(false)
              setHasErrors(true)
              throw new Error("error")
            }
            newArray = [...newArray, resolve.data.filename_disk]
            // console.log("newArray: ", newArray)
          }

          const dataSend = {
            titulo: title.value,
            tipo: "imagen",
            autor: name.value,
            image1: newArray[0],
            image2: newArray[1],
            image3: newArray[2] ? newArray[2] : "",
            image4: newArray[3] ? newArray[3] : "",
            image5: newArray[4] ? newArray[4] : "",
            estado: state.value,
            correo: email.value,
            descripcion: description.value,
          }

          return setFormData(dataSend)
        })
        .then(() => {
          navigate(`/sube-tu-historia/enviado/`)
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error)
        })

      return
    }

    coverFile.current.inputValidate()
    inputTitle.current.inputValidate()
    inputName.current.inputValidate()
    inputDescription.current.inputValidate()
    inputEmail.current.inputValidate()
    inputImages.current.inputValidateImages()
    checkboxTerms.current.inputValidate()
    checkboxAuthorize.current.inputValidate()
    checkboxCopyright.current.inputValidate()
    inputPlace.current.inputValidate()

    setSendData(false)
    setErrorMessage("Verifica que toda la información este completa.")
    setHasErrors(true)
  }

  const handleModal = () => {
    setIsOpen(true)
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <CoverForm setStateCover={setCover} setStateTitle={setTitle} setStateName={setName} setStateLocation={setState} className={`${classNameID}__cover`} refCover={coverFile} refTitle={inputTitle} refPlace={inputPlace} refName={inputName} option={option} setOption={setOption} />
        <div className={`${classNameID}__content`}>
          <Container>
            <Row>
              <Col xs={4} sm={8} md={6} lg={6} xl={6}>
                <UploadImages className={`${classNameID}__uploadImages`} setImages={setImages} images={images} refImages={inputImages} />
              </Col>
              <Col xs={4} sm={8} md={5} offset={{ md: 1 }}>
                <div className={`${classNameID}__contentBody`}>
                  <h4 className={`${classNameID}__instruction`}>Completa los datos solicitados para enviar tu historia</h4>
                  <InputContentEditable name="description" placeholder="Aquí puedes contarnos la historia de tu jeep..." isRequired requiredMessage="Por favor cuentanos tu historia" setState={setDescription} ref={inputDescription} />
                  <Divisor />
                  <h4 className={`${classNameID}__instruction`}>No te preocupes por tu privacidad, tu correo no será público.</h4>
                  <InputText
                    id="email"
                    name="email"
                    className={`${classNameID}__email`}
                    placeholder="ingresa@tucorreo.com"
                    errorClass="input-email--error"
                    isRequired
                    requiredMessage="Por favor ingresa tu email"
                    setState={setEmail}
                    optionsRegex={{
                      regex: regex.email,
                      message: "El email que ingresaste no es válido",
                    }}
                    ref={inputEmail}
                  />
                  <h4 className={`${classNameID}__legal`}>
                    Asegúrate de contar con los derechos del material que subas; para más información haz
                    <a href="#" onClick={handleModal} className="tag-formulario-imagen-derechos-autor">
                      {" "}
                      click aquí.
                    </a>
                  </h4>
                  <Checkbox className={`${classNameID}__checkbox`} label="Acepto términos y condiciones" requiredMessage="Para continuar por favor acepta nuestros términos y condiciones" isRequired setState={setTerms} ref={checkboxTerms} />
                  <Checkbox className={`${classNameID}__checkbox`} label="Autorizo para que Jeep México utilice mi contenido" requiredMessage="Para continuar necesitas autorizar el uso de tu contenido" isRequired setState={setAuthorize} ref={checkboxAuthorize} />
                  <Checkbox className={`${classNameID}__checkbox`} label="Tengo los derechos del material que comparto" requiredMessage="Para continuar acepta los derechos del contenido" isRequired setState={setCopyright} ref={checkboxCopyright} />
                  <ButtonText themeType={ButtonTextType.dark} sendData={sendData} type="submit" classTag="tag-formulario-imagen-enviar">
                    {sendData ? "Enviando..." : "Enviar"}
                  </ButtonText>
                  <div>{hasErrors && <InputError className="form-image--error">{errorMessage}</InputError>}</div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {isOpen && <TermsPopUp isOpen={isOpen} setIsOpen={setIsOpen} />}
      </FormContainer>
    </form>
  )
}

export default FormImage
