import React, { useEffect, useContext } from "react"
import ReactPlayer from "react-player/lazy"

import LayoutContext from "../../layout/layout.context"
import SvgCancel from "../svgs/SvgCancel"
import { ContainerVideo, Modal } from "./videoPopUp.styles"

const VideoPopUp = ({ isOpen, setIsOpen }) => {
  const url = "https://grwpo.s3-us-west-1.amazonaws.com/jeepstories/tips/video-tips.mp4"
  const { setIsActiveModal } = useContext(LayoutContext)

  const closeModal = () => {
    setIsOpen(false)
    setIsActiveModal(false)
  }

  useEffect(() => {
    setIsActiveModal(isOpen)
  }, [isOpen])

  return (
    <Modal>
      <ContainerVideo>
        <button type="button" onClick={closeModal} className="close_black_modal">
          <div className="black_modal" />
        </button>
        <div className="video_modal">
          <ReactPlayer className="react-player" url={url} width="100%" height="100%" autoPlay playing controls playsinline />
          <button type="button" onClick={closeModal} className="close_modal">
            <SvgCancel />
          </button>
        </div>
      </ContainerVideo>
    </Modal>
  )
}

export default VideoPopUp
