import * as React from "react"

function SvgImage({ width = "100", height = "100", className = "" }) {
  return (
    <svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 100 100" xmlSpace="preserve">
      <path d="M90.6 0H9.4C4.2 0 0 4.2 0 9.4v81.2c0 5.2 4.2 9.4 9.4 9.4h81.2c5.2 0 9.4-4.2 9.4-9.4V9.4c0-5.2-4.2-9.4-9.4-9.4zm3.2 90.6c0 1.7-1.4 3.1-3.1 3.1H9.4c-1.7 0-3.1-1.4-3.1-3.1V9.4c0-1.7 1.4-3.1 3.1-3.1h81.2c1.7 0 3.1 1.4 3.1 3.1v81.2z" fill="currentColor" />
      <path d="M67.8 54c-1.2-1.2-3.2-1.2-4.4 0l-7.2 7.2-13.4-13.4c-1.2-1.2-3.2-1.2-4.4 0L16.6 69.7c-1.2 1.2-1.2 3.2 0 4.4.6.6 1.3.9 2.1.9h62.5c1.7 0 3.1-1.4 3.1-3.1 0-.8-.3-1.6-.9-2.2L67.8 54z" fill="currentColor" />
      <circle cx={59.4} cy={34.4} r={9.4} fill="currentColor" />
    </svg>
  )
}

export default SvgImage
