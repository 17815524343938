import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

const ButtonEditContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;

  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  svg {
    height: 15px;
    width: 15px;
    fill: ${props => props.theme.vars.white};
    pointer-events: none;
  }
  .o-button-edit__bg {
    position: absolute;
    width: 97%;
    height: 97%;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .o-button-edit__bgroll {
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.vars.goldenTainoi};
    opacity: 0;
    transition: all 0.5s ease;
    z-index: 2;
    pointer-events: none;
  }
  .o-button-edit__bgnormal {
    background-color: ${props => props.theme.vars.charade};
    z-index: 1;
    pointer-events: none;
  }

  .o-button-edit__svg {
    position: absolute;
    transition: all 0.5s ease;
    z-index: 2;
  }
  .o-button-edit__svgroll {
    fill: ${props => props.theme.vars.outerSpace};
    transform: translateY(-250%);
  }
  &:hover {
    @media ${device.md} {
      .o-button-edit__bgroll {
        opacity: 1;
      }
      .o-button-edit__svgnormal {
        transform: translateY(250%);
      }
      .o-button-edit__svgroll {
        transform: translateY(0);
      }
    }
  }
  @media ${device.md} {
    width: 70px;
    height: 70px;
    svg {
      height: 20px;
      width: 20px;
    }
  }
  @media ${device.lg} {
    width: 80px;
    height: 80px;
  }
`
export default ButtonEditContainer
