import styled from "styled-components"
import { device } from "../../../../theme/mediaQueries"
import textura320 from "../../../../../static/assets/images/formOptions/textura320.jpg"
import textura768 from "../../../../../static/assets/images/formOptions/textura768.jpg"

export const FormOptionsContainer = styled.div`
  position: relative;
  /* overflow: scroll; */
  height: 100%;
  width: 100%;
  background-image: url(${textura320});
  background-position: center;
  background-repeat: repeat;
  @media ${device.sm} {
    background-image: url(${textura768});
    height: auto;
  }
`
export const FormOptionsStage = styled.div`
  position: relative;
  @media ${device.sm} {
    height: calc(100vh - 126px);
    min-height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media ${device.md} {
    height: calc(100vh - 80px);
    min-height: 650px;
  }
  @media ${device.lg} {
    min-height: 700px;
  }
  @media ${device.xl} {
    height: calc(100vh - 111px);
    min-height: 950px;
  }
`
export const ContainerInfo = styled.div`
  height: 100%;
  text-align: center;
  font-family: ${props => props.theme.vars.avenirNext};
  margin-top: 120px;
  @media ${device.sm} {
    height: auto;
    margin-top: 130px;
  }

  h1 {
    text-transform: uppercase;
    font-size: calc(9.5vw);
    padding: 0 30px;
    font-weight: ${props => props.theme.vars.fontBlack};
    margin-bottom: 40px;
    @media ${device.sm} {
      font-size: 51.8px;
      padding: 0 80px;
    }
    @media ${device.md} {
      padding: 0;
      font-size: 51.4px;
    }
    @media ${device.lg} {
      font-size: 52px;
      padding: 0 100px;
      margin-bottom: 12px;
    }
    @media ${device.xl} {
      font-size: 102px;
      padding: 0 250px;
      margin-bottom: 30px;
    }
  }
  h2 {
    font-weight: ${props => props.theme.vars.fontSemiBold};
    font-size: calc(7.5vw);
    margin-bottom: 20px;
    @media ${device.sm} {
      font-size: 24px;
      margin-bottom: 0px;
    }
    @media ${device.md} {
      margin-bottom: 10px;
    }
    @media ${device.lg} {
      font-size: 30px;
    }
  }
  p {
    font-size: calc(5.7vw);
    font-weight: ${props => props.theme.vars.fontRegular};
    font-family: ${props => props.theme.vars.openSans};
    line-height: 1.5;
    margin-bottom: 40px;
    @media ${device.sm} {
      font-size: 18px;
      margin-bottom: 90px;
    }
    @media ${device.md} {
      margin-bottom: 40px;
    }
    @media ${device.lg} {
      margin-bottom: 44px;
    }
    @media ${device.xl} {
      margin-bottom: 60px;
    }
  }
  .option_button_type {
    width: 108px;
    height: 108px;
    margin-bottom: 25px;
    box-shadow: 0px 1px 5px #999;
    display: flex;
    .o-option-iconregular {
      color: ${props => props.theme.vars.charade};
    }
    .o-option-iconroll {
      color: ${props => props.theme.vars.white};
    }
    @media ${device.lg} {
      margin-bottom: 40px;
      width: 120px;
      height: 120px;
    }
    @media ${device.xl} {
      width: 150px;
      height: 150px;
    }
  }
  .o-option-bgroll {
    background-color: ${props => props.theme.vars.charade};
  }
  .o-option-bg {
    background-color: ${props => props.theme.vars.goldenTainoi};
  }
`

export const ContainerOptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  @media ${device.sm} {
    flex-direction: row;
    margin-bottom: 50px;
    justify-content: space-between;
    .options:nth-of-type(3) {
      order: -1;
    }
  }
  @media ${device.md} {
    padding: 0 130px;
  }
  @media ${device.lg} {
    padding: 0 200px;
  }
  @media ${device.xl} {
    margin-bottom: 30px;
    padding: 0 250px;
  }

  .options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    h1 {
      font-family: ${props => props.theme.vars.avenirNext};
      font-size: calc(4.4vw);
      letter-spacing: 1px;
      @media ${device.sm} {
        font-size: 14px;
      }
    }
    button {
      cursor: pointer;
      background-color: transparent;
      border: none;
      outline: none;
    }
  }
`

export const ContainerInspiracion = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  @media ${device.sm} {
    margin-bottom: 0;
  }
  @media ${device.md} {
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 60px;
    padding: 0 0 0 100px;
  }
  @media ${device.lg} {
    justify-content: center;
    flex-direction: column;
    padding: 0 150px;
  }
  .text-inspiracion {
    @media ${device.md} {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 48px;
    }
    @media ${device.lg} {
      margin-bottom: 18px;
    }
    h2 {
      font-weight: ${props => props.theme.vars.fontSemiBold};
      font-size: calc(5vw);
      margin-bottom: 7px;
      @media ${device.sm} {
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: ${props => props.theme.vars.fontBold};
      }
      @media ${device.md} {
        margin-bottom: 0;
        font-size: 16px;
      }
      @media ${device.lg} {
        font-size: 18px;
      }
    }
    p {
      font-size: calc(4.4vw);
      font-weight: ${props => props.theme.vars.fontRegular};
      font-family: ${props => props.theme.vars.openSans};
      line-height: 1.5;
      margin-bottom: 30px;
      @media ${device.sm} {
        font-size: 14.5px;
        margin-bottom: 30px;
      }
      @media ${device.md} {
        margin: 0 0 3px 5px;
        font-size: 14px;
      }
      @media ${device.lg} {
        font-size: 14.5px;
        margin: 0 0 0 5px;
      }
      @media ${device.xl} {
        font-size: 15.6px;
      }
    }
  }
  .Button_Tips {
    width: 130px;
    font-size: 14px;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.2);

    @media ${device.sm} {
      margin-bottom: 35px;
    }
    @media ${device.md} {
      margin-bottom: 0px;
      margin-left: 20px;
    }
    @media ${device.lg} {
      width: 200px;
      margin: 0;
    }
  }
  .Button_Tips:hover {
    cursor: pointer;
  }
`
