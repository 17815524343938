import React, { useState, useRef, useImperativeHandle, forwardRef } from "react"
import SvgLink from "../svgs/SvgLink"
import InputError from "../inputError/InputError"
import regex from "../../../helpers/regex"
import { isEmpty } from "../../../helpers/object"

import { InputUrlContainer, InputUrl, ButtonUrl } from "./InputUrlVideo.style"

const InputUrlVideo = ({ placeholder, className = "", errorClass = "", optionsRegex, isRequired, requiredMessage, setState }, refImperative) => {
  const inputTextRef = useRef()
  const [error, setError] = useState(null)

  const validateInput = () => {
    const { value } = inputTextRef.current

    // check if input is required and hasn't value
    if (isRequired && !regex.empty.test(value)) {
      setError(requiredMessage)
      setState(prevValue => {
        return {
          ...prevValue,
          ...{ isValid: false },
        }
      })
      return
    }
    if (!isEmpty(optionsRegex) && !optionsRegex.regex.test(value)) {
      // check additional validation
      setError(optionsRegex.message)
      setState(prevValue => {
        return {
          ...prevValue,
          ...{ isValid: false },
        }
      })
      return
    }

    setState(prevValue => {
      return {
        ...prevValue,
        ...{ value, isValid: true },
      }
    })
    setError(null)
  }

  useImperativeHandle(refImperative, () => ({
    inputValidate: () => {
      validateInput()
    },
  }))

  return (
    <InputUrlContainer className={className}>
      <InputUrl>
        <SvgLink />
        <input type="text" placeholder={placeholder} ref={inputTextRef} onChange={validateInput} />
      </InputUrl>
      <ButtonUrl>Actualizar</ButtonUrl>
      {error && <InputError className={errorClass}>{error}</InputError>}
    </InputUrlContainer>
  )
}

export default forwardRef(InputUrlVideo)
