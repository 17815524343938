import React from "react"
import SvgPlus from "../svgs/SvgPlus"
import { ButtonContainer, SvgContainer } from "./ButtonAddImage.style"

const ButtonAddImage = ({ className = "", count, setCount, setEnablesImages }) => {
  const classNameID = "o-button-addimage"
  const handleClick = () => {
    const newCount = count + 1
    setCount(newCount)
    setEnablesImages(prevValue => [...prevValue, { id: newCount }])
  }

  return (
    <ButtonContainer onClick={handleClick} className={className}>
      <SvgContainer>
        <div className={`${classNameID}__bgroll`} />
        <SvgPlus className={`${classNameID}__svgnormal ${classNameID}__svg`} />
        <SvgPlus className={`${classNameID}__svgroll ${classNameID}__svg`} />
      </SvgContainer>
      <span>Agregar imagen</span>
    </ButtonContainer>
  )
}

export default ButtonAddImage
