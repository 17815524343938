import React from "react"
import SvgEdit from "../svgs/SvgCancel"

import ButtonCloseContainer from "./ButtonClose.style"

const ButtonClose = ({ className, handleClick }) => {
  return (
    <ButtonCloseContainer className={className} onClick={handleClick}>
      <div className="o-button-edit__bgnormal o-button-edit__bg" />
      <div className="o-button-edit__bgroll o-button-edit__bg" />
      <SvgEdit className="o-button-edit__svgnormal o-button-edit__svg" />
      <SvgEdit className="o-button-edit__svgroll o-button-edit__svg" />
    </ButtonCloseContainer>
  )
}

export default ButtonClose
