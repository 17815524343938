import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

const ButtonTypeRollStyle = styled.button`
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 1px 5px #999;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  img {
    width: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .o-option-content {
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.5s ease;
    pointer-events: none;
    svg {
      width: 30px;
      height: 30px;
    }
  }
  .o-option-icon {
    transition: all 0.5s ease;
  }
  .o-option-iconroll {
    transform: translate(-50%, -250%);
    opacity: 0;
    @media ${device.md} {
      display: block;
    }
  }
  .o-option-iconregular {
    opacity: 1;
  }
  .o-option-bgroll {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    pointer-events: none;
    border-radius: 50%;
    opacity: 0;
    transition: all 0.5s ease;
  }
  .o-option-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    border-radius: 50%;
    pointer-events: none;
  }

  &:hover {
    @media ${device.md} {
      .o-option-bgroll {
        opacity: 1;
      }
      .o-option-bg {
        width: 98%;
        height: 98%;
      }
      .o-option-iconregular {
        transform: translate(-50%, 250%);
        opacity: 0;
      }
      .o-option-iconroll {
        transform: translate(-50%, -50%);
        opacity: 1;
      }
    }
  }
`

export default ButtonTypeRollStyle
