import React, { useState } from "react"
import ReactPlayer from "react-player/lazy"

import useBreakpointWidth from "../../../hooks/useBreakpointWidth"
import SvgImage from "../svgs/SvgImage"
import VideoContainer from "./VideoDetail.styles"

const VideoDetail = ({ url }) => {
  const [showIcon, setShowIcon] = useState(true)
  const size = useBreakpointWidth()

  const onReady = () => {
    setShowIcon(false)
  }

  return (
    <VideoContainer className="c-video">
      {size.min < 768 && <ReactPlayer className="react-player" url={url} width="300px" height="168px" onReady={onReady} />}
      {size.min < 1024 && size.min >= 768 && <ReactPlayer className="react-player" url={url} width="648px" height="366px" onReady={onReady} />}
      {size.min < 1360 && size.min >= 1024 && <ReactPlayer className="react-player" url={url} width="904px" height="509px" onReady={onReady} />}
      {size.min < 1920 && size.min >= 1360 && <ReactPlayer className="react-player" url={url} width="1004px" height="565px" onReady={onReady} />}
      {size.min >= 1920 && <ReactPlayer className="react-player" url={url} width="1369px" height="768px" onReady={onReady} />}
      {showIcon && <SvgImage className="image-svg" />}
    </VideoContainer>
  )
}

export default VideoDetail
