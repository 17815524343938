import styled from "styled-components"
import { device } from "../../../../theme/mediaQueries"

export const FormContainer = styled.div`
  position: relative;
  padding-bottom: 50px;

  .form--image__cover {
    margin-bottom: 48px;
  }
  .form--image__uploadImages {
    margin-bottom: 60px;
  }
  .form--image__content .c-button--text_contentroll {
    color: ${props => props.theme.vars.charade};
  }
  .form--image__instruction {
    font-family: ${props => props.theme.vars.openSans};
    font-weight: ${props => props.theme.vars.fontRegular};
    font-size: 12px;
    line-height: 16px;
    color: ${props => props.theme.vars.codGray};
    opacity: 0.6;
    margin-bottom: 20px;
  }
  .form--image__email {
    margin-bottom: 48px;
    input {
      font-family: ${props => props.theme.vars.avenirNext};
      font-weight: ${props => props.theme.vars.fontSemiBold};
      font-size: 16px;
      &::placeholder {
        color: ${props => props.theme.vars.charade};
        opacity: 1;
      }
    }
  }
  .form--image__legal {
    font-family: ${props => props.theme.vars.openSans};
    font-weight: ${props => props.theme.vars.fontRegular};
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 40px;
    a {
      font-weight: ${props => props.theme.vars.fontBold};
    }
  }
  .c-button--text {
    margin: 0;
  }
  /* Warnings */
  .form-image--error {
    display: inline-flex;
    min-width: 230px;
  }
  .input-email--error {
    position: absolute;
    bottom: -45px;
    left: 0px;
  }
  /* Warnings */

  @media ${device.sm} {
    .form--image__cover {
      margin-bottom: 55px;
    }
    .form--image__uploadImages {
      margin-bottom: 38px;
    }
    .form--image__contentBody {
      position: relative;
      z-index: 1;
    }
    .form--image__instruction {
      font-size: 14px;
      padding-left: 22px;
      margin-bottom: 16px;
    }
    .form--image__email {
      margin-bottom: 55px;
      input {
        font-size: 18px;
        padding: 16px 18px;
      }
    }
    .form--image__legal {
      font-size: 16px;
      line-height: 29px;
      padding: 0px 22px;
      margin-bottom: 32px;
    }
    .form--image__checkbox {
      padding-left: 22px;
    }
    /* Warnings */
    .form-image--error {
      min-width: 280px;
    }
    /* Warnings */
  }
  @media ${device.md} {
    .form--image__email {
      margin-bottom: 45px;
    }
    .form--image__legal {
      font-size: 18px;
      line-height: 26px;
      padding: 0px;
    }
    .form--image__checkbox {
      padding-left: 10px;
    }
    /* Warnings */
    .input-email--error {
      bottom: -41px;
    }
    /* Warnings */
  }
  @media ${device.lg} {
    .form--image__email {
      input {
        font-size: 20px;
      }
    }
    .form--image__checkbox {
      padding-left: 24px;
    }
  }
  @media ${device.xl} {
    .form--image__cover {
      margin-bottom: 92px;
    }
    .form--image__content {
      margin-top: -200px;
    }
    .form--image__instruction {
      font-size: 16px;
      line-height: 36px;
      padding-left: 24px;
      margin-bottom: 22px;
    }
    .form--image__email {
      margin-bottom: 55px;
      input {
        font-size: 22px;
        padding: 20px;
      }
    }
    .form--image__legal {
      padding: 0px 24px;
      margin-bottom: 44px;
    }
    /* Warnings */
    .form-image--error {
      min-width: 312px;
    }
    /* Warnings */
  }
`
export const Divisor = styled.div`
  height: 1px;
  width: calc(100% - 20px);
  background-color: rgba(0, 0, 0, 0.25);
  margin: 50px auto;

  @media ${device.sm} {
    width: calc(100% - 44px);
  }
  @media ${device.xl} {
    width: calc(100% - 48px);
    margin: 60px auto;
  }
`
