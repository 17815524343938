import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

export const ButtonContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  span {
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    color: ${props => props.theme.vars.charade};
    max-width: 60px;
    text-align: center;
    pointer-events: none;
  }
  .o-button-addimage__bgroll {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${props => props.theme.vars.outerSpace};
    opacity: 0;
    transition: all 0.5s ease;
    pointer-events: none;
  }

  @media ${device.xl} {
    span {
      font-size: 16px;
    }
  }
`

export const SvgContainer = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px dotted ${props => props.theme.vars.charade};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  overflow: hidden;
  pointer-events: none;

  @media ${device.sm} {
    width: 60px;
    height: 60px;
  }
  @media ${device.xl} {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
  }
  .o-button-addimage__svg {
    position: absolute;
    transition: all 0.5s ease;
  }
  .o-button-addimage__svgroll {
    color: ${props => props.theme.vars.titanWhite};
    transform: translateY(-200%);
  }
  &:hover {
    @media ${device.md} {
      border: none;
      .o-button-addimage__bgroll {
        opacity: 1;
      }
      .o-button-addimage__svgnormal {
        transform: translateY(200%);
      }
      .o-button-addimage__svgroll {
        transform: translateY(0);
      }
    }
  }
`
export const SvgBG = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px dotted ${props => props.theme.vars.charade};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  @media ${device.sm} {
    width: 60px;
    height: 60px;
  }
  @media ${device.xl} {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
  }
`
