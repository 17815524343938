import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"

import InputFile from "../../elements/inputFile/InputFile"
import ButtonEdit from "../../elements/buttonEdit/ButtonEdit"
import ButtonClose from "../../elements/buttonClose/ButtonClose"

import { UploadImageContainer, UploadActions, UploadAction } from "./UploadImage.style"

// import jeepLogo from "../../../images/jeep-aniversario-logo.png"

const UploadImage = ({ id, className = "", enablesImages, setEnablesImages, isFirst = false, images, setImages, requiredMessage, inputFileRef }) => {
  const [imgData, setImgdata] = useState(null)
  const [hideInput, setHideInput] = useState(false)
  const [activeActions, setActiveActions] = useState(false)

  const handleDeleteUploadImage = () => {
    setEnablesImages(enablesImages.filter(image => image.id !== id))
    setImages(images.filter(image => image.id !== id))
  }

  const handleChange = (value, isValid) => {
    setImages(images.filter(image => image.id !== id))
    setImages(prevState => [...prevState, { id, url: value, isValid }])
  }

  return (
    <UploadImageContainer className={`${className} ${id === 2 ? "marginTop" : ""}`}>
      {isFirst && (
        <div className="upload--hashtag">
          <StaticImage className="upload-img--jeepLogo" src="../../../images/jeep-aniversario-logo.png" alt="Jeep 80 aniversario" placeholder="none" objectFit="contain" />
          <span>#jeepstories</span>
        </div>
      )}
      <div className={`upload--img__container ${imgData ? "is-white" : ""} ${activeActions ? "active-actions" : ""}`}>
        {imgData && <img className="upload-img" src={imgData} alt="" />}
        <InputFile className={`ulpload--img__input ${hideInput && "hide"}`} setImgData={setImgdata} description="Dimensión sugerida 1200x1200 y tamaño máximo 10 MB." showButtonDelete={false} setHideInput={setHideInput} ref={inputFileRef} onChange={handleChange} isFirstImage={isFirst} requiredMessage={requiredMessage} classTag="tag-formulario-imagen-cargar-imagen" />
        {imgData && <ButtonEdit className="upload--button__edit tag-formulario-imagen-abrir-editor" setActiveActions={setActiveActions} activeActions={activeActions} />}
        {imgData !== null || (id > 1 && <ButtonClose className="upload--button__edit tag-formulario-imagen-quitar-imagen" handleClick={handleDeleteUploadImage} />)}
        {activeActions && (
          <UploadActions>
            <UploadAction
              onClick={() => {
                inputFileRef.current.callEventChangeFile()
              }}
              className="tag-formulario-imagen-cambiar-imagen"
            >
              Cambiar Imagen
            </UploadAction>
            {!isFirst && (
              <UploadAction onClick={handleDeleteUploadImage} className="tag-formulario-imagen-quitar-imagen">
                Quitar
              </UploadAction>
            )}
          </UploadActions>
        )}
      </div>
    </UploadImageContainer>
  )
}
export default UploadImage
