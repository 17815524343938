const userMediaCapable = setEstado => {
  let isValid = false

  /* eslint-disable no-unused-vars */
  const promisifiedOldGUM = (constraints, successCallback, errorCallback) => {
    // First get ahold of getUserMedia, if present
    const getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia

    // Some browsers just don't implement it - return a rejected promise with an error
    // to keep a consistent interface
    if (!getUserMedia) {
      return Promise.reject(new Error("getUserMedia is not implemented in this browser"))
    }

    // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
    /* eslint-disable no-shadow */
    return new Promise((successCallback, errorCallback) => {
      getUserMedia.call(navigator, constraints, successCallback, errorCallback)
    })
  }

  // Older browsers might not implement mediaDevices at all, so we set an empty object first
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {}
  }

  // Some browsers partially implement mediaDevices. We can't just assign an object
  // with getUserMedia as it would overwrite existing properties.
  // Here, we will just add the getUserMedia property if it's missing.
  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = promisifiedOldGUM
  }

  // Prefer camera resolution nearest to 1280x720.
  const constraints = { audio: true }

  navigator.mediaDevices
    .getUserMedia(constraints)
    /* eslint-disable no-unused-vars */
    .then(stream => {
      isValid = true
      setEstado(isValid)
      return true
    })
    /* eslint-disable no-unused-vars */
    .catch(err => {
      isValid = false
      setEstado(isValid)
      return false
    })
}

export default userMediaCapable
