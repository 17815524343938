import React, { useState, useRef } from "react"
import { Container, Row, Col } from "react-awesome-styled-grid"
import { navigate } from "gatsby"

import regex from "../../../../helpers/regex"
import { setUploadImages, setFormData } from "../../../../middleware"
import CoverForm from "../../coverForm/CoverForm"
import VideoDetail from "../../../elements/videoDetail/VideoDetail"
import InputUrlVideo from "../../../elements/inputUrlVideo/InputUrlVideo"
import InputText from "../../../elements/inputText/InputText"
import Checkbox from "../../../elements/checkBox/CheckBox"
import ButtonText, { ButtonTextType } from "../../../elements/buttonText/ButtonText"
import InputContentEditable from "../../../elements/inputContentEditable/InputContentEditable"
import TermsPopUp from "../../../elements/termsPopUp/termsPopUp"
import InputError from "../../../elements/inputError/InputError"
import { FormContainer, Divisor } from "./FormVideo.style"

const FormVideo = ({ option, setOption }) => {
  const classNameID = "form--video"
  const [isOpen, setIsOpen] = useState(false)

  const inputPlace = useRef()
  const coverFile = useRef()
  const inputTitle = useRef()
  const inputName = useRef()
  const inputUrlVideo = useRef()
  const inputDescription = useRef()
  const inputEmail = useRef()
  const checkboxTerms = useRef()
  const checkboxAuthorize = useRef()
  const checkboxCopyright = useRef()

  const [cover, setCover] = useState({ value: null, isValid: false })
  const [title, setTitle] = useState({ value: null, isValid: false })
  const [name, setName] = useState({ value: null, isValid: false })
  const [state, setState] = useState({
    value: "AGUASCALIENTES",
    isValid: true,
  })
  const [urlVideo, setUrlVideo] = useState({ value: null, isValid: false })
  const [email, setEmail] = useState({ value: null, isValid: false })
  const [description, setDescription] = useState({
    value: null,
    isValid: false,
  })
  const [terms, setTerms] = useState(false)
  const [authorize, setAuthorize] = useState(false)
  const [copyright, setCopyright] = useState(false)

  const [sendData, setSendData] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)
  const [errorMessage, setErrorMessage] = useState("Verifica que toda la información este completa.")

  const handleSubmit = e => {
    e.preventDefault()

    setSendData(true)

    if (cover.isValid && title.isValid && name.isValid && state.isValid && urlVideo.isValid && email.isValid && description.isValid && terms && authorize && copyright) {
      const promiseOne = setUploadImages(cover.value)
      setHasErrors(false)

      let coverImage = ""

      promiseOne
        .then(resolve => {
          if (resolve.error !== undefined && resolve.error === 1) {
            setErrorMessage(resolve.data.message)
            setSendData(false)
            setHasErrors(true)
            throw new Error("error")
          }
          coverImage = resolve.data.filename_disk
          const dataSend = {
            titulo: title.value,
            tipo: "video",
            autor: name.value,
            image1: coverImage,
            videolink: urlVideo.value,
            estado: state.value,
            correo: email.value,
            descripcion: description.value,
          }

          return setFormData(dataSend)
        })
        .then(() => {
          navigate(`/sube-tu-historia/enviado/`)
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error)
        })

      return
    }

    coverFile.current.inputValidate()
    inputTitle.current.inputValidate()
    inputName.current.inputValidate()
    inputUrlVideo.current.inputValidate()
    inputDescription.current.inputValidate()
    inputEmail.current.inputValidate()
    checkboxTerms.current.inputValidate()
    checkboxAuthorize.current.inputValidate()
    checkboxCopyright.current.inputValidate()
    inputPlace.current.inputValidate()

    setSendData(false)
    setHasErrors(true)
  }

  const handleModal = () => {
    setIsOpen(true)
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <CoverForm setStateCover={setCover} setStateTitle={setTitle} setStateName={setName} setStateLocation={setState} className={`${classNameID}__cover`} refCover={coverFile} refTitle={inputTitle} refName={inputName} refPlace={inputPlace} option={option} setOption={setOption} />
        <div className={`${classNameID}__content`}>
          <VideoDetail url={urlVideo.value} />
          <Container>
            <Row>
              <Col sm={8} md={10} lg={8} xl={8} offset={{ md: 1, lg: 2, xl: 2 }}>
                <h4 className={`${classNameID}__instruction`}>Carga tu video copiando el link desde youtube o vimeo</h4>
                <InputUrlVideo
                  className={`${classNameID}__urlVideo`}
                  placeholder="Ej. https://www.youtube.com/watch?v=zGtQyF73e_M"
                  errorClass="input-urlVideo--error"
                  isRequired
                  requiredMessage="Por favor ingresa la url de tu video"
                  optionsRegex={{
                    regex: regex.urlVideo,
                    message: "La url no es válida",
                  }}
                  setState={setUrlVideo}
                  ref={inputUrlVideo}
                />
                <h4 className={`${classNameID}__instruction`}>Completa los datos solicitados para enviar tu historia</h4>
                <InputContentEditable name="description" placeholder="Aquí puedes contarnos la historia de tu jeep..." isRequired requiredMessage="Por favor cuentanos tu historia" setState={setDescription} ref={inputDescription} />
                <Divisor />
                <h4 className={`${classNameID}__instruction`}>No te preocupes por tu privacidad, tu correo no será público.</h4>
                <InputText
                  id="email"
                  name="email"
                  className={`${classNameID}__email`}
                  placeholder="ingresa@tucorreo.com"
                  errorClass="input-email--error"
                  isRequired
                  requiredMessage="Por favor ingresa tu email"
                  setState={setEmail}
                  optionsRegex={{
                    regex: regex.email,
                    message: "El email que ingresaste no es válido",
                  }}
                  ref={inputEmail}
                />
                <h4 className={`${classNameID}__legal`}>
                  Asegúrate de contar con los derechos del material que subas; para más información haz
                  <a href="#" onClick={handleModal} className="tag-formulario-video-derechos-autor">
                    {" "}
                    click aquí.
                  </a>
                </h4>
                <Checkbox className={`${classNameID}__checkbox`} label="Acepto términos y condiciones" requiredMessage="Para continuar por favor acepta nuestros términos y condiciones" isRequired setState={setTerms} ref={checkboxTerms} />
                <Checkbox className={`${classNameID}__checkbox`} label="Autorizo para que Jeep México utilice mi contenido" requiredMessage="Para continuar necesitas autorizar el uso de tu contenido" isRequired setState={setAuthorize} ref={checkboxAuthorize} />
                <Checkbox className={`${classNameID}__checkbox`} label="Tengo los derechos del material que comparto" requiredMessage="Para continuar acepta los derechos del contenido" isRequired setState={setCopyright} ref={checkboxCopyright} />
                <ButtonText themeType={ButtonTextType.dark} sendData={sendData} type="submit" classTag="tag-formulario-video-enviar">
                  {sendData ? "Enviando..." : "Enviar"}
                </ButtonText>
                <div>{hasErrors && <InputError className="form-video--error">{errorMessage}</InputError>}</div>
              </Col>
            </Row>
          </Container>
        </div>
        {isOpen && <TermsPopUp isOpen={isOpen} setIsOpen={setIsOpen} />}
      </FormContainer>
    </form>
  )
}

export default FormVideo
