import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

export const ContainerVideo = styled.div`
  align-items: center;
  display: flex;
  font-size: 0;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;

  .black_modal {
    background-color: rgba(10, 10, 10, 0.93);
    height: 100%;
    width: 100%;
  }
  .video_modal {
    position: relative;
    margin: 0 30px;
    max-width: 520px;
    width: 100%;
    @media ${device.sm} {
      margin: 0;
      max-width: 650px;
    }
    @media ${device.md} {
      max-width: 905px;
    }
    @media ${device.lg} {
      max-width: 1095px;
    }
    @media ${device.xl} {
      max-width: 1365px;
    }
  }
  .close_black_modal {
    border: none;
    background-color: transparent;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .close_modal {
    border: none;
    color: ${props => props.theme.vars.white};
    cursor: pointer;
    background-color: transparent;
    outline: none;
    position: absolute;
    top: 6px;
    right: 4px;
    font-family: ${props => props.theme.vars.avenirNext};
    font-weight: ${props => props.theme.vars.fontRegular};
    svg {
      height: 20px;
      width: 20px;
    }
    @media ${device.sm} {
      top: 10px;
      right: 4px;
      svg {
        height: 25px;
        width: 25px;
      }
    }
    @media ${device.md} {
      top: 12px;
      right: 10px;
      svg {
        height: 30px;
        width: 30px;
      }
    }
    @media ${device.xl} {
      top: 15px;
      right: 13px;
      svg {
        height: 35px;
        width: 35px;
      }
    }
  }
  .react-player {
    position: relative;
    @media ${device.md} {
      height: 100% !important;
    }
  }
`

export const Modal = styled.div`
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  pointer-events: auto;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 20;
`
