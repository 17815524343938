import React, { useState, useEffect, useContext } from "react"
import { Container, Row, Col } from "react-awesome-styled-grid"
// import ButtonText, { ButtonTextType } from "../../../elements/buttonText/ButtonText"
import LayoutContext from "../../../layout/layout.context"
import {
  FormOptionsContainer,
  FormOptionsStage,
  ContainerInfo,
  ContainerOptions,
  //  ContainerInspiracion
} from "./FormOptions.style"
import VideoPopUp from "../../../elements/videoPopUp/videoPopUp"
import ButtonTypeRoll from "../../../elements/buttonTypeRoll/ButtonTypeRoll"
import userMediaCapable from "../../../../helpers/userMediaCapable"

const FormOptions = ({ setOption }) => {
  const context = useContext(LayoutContext)
  const [isOpen, setIsOpen] = useState(false)
  const [isAudioCapable, setIsAudioCapable] = useState(false)

  useEffect(() => {
    context.setFormOption(true)
    userMediaCapable(setIsAudioCapable)

    return () => {
      context.setFormOption(undefined)
    }
  }, [])

  const handleClick = type => {
    setOption(type)
  }

  // const handleModal = () => {
  //   setIsOpen(true)
  // }

  return (
    <FormOptionsContainer>
      <Container>
        <Row>
          <Col sm={8} md={12} lg={12} xl={12} offset={{}}>
            <FormOptionsStage>
              <ContainerInfo>
                <h1>Compártenos tus jeepstories</h1>
                <h2>Instrucciones</h2>
                <p>Selecciona el formato en el que quieres contarnos tu historia:</p>
                <ContainerOptions>
                  <div className="options">
                    <ButtonTypeRoll type="camera" className="option_button_type tag-crea-historia-imagen" onClick={() => handleClick("imagen")} />
                    <h1>IMAGEN</h1>
                  </div>
                  <div className="options">
                    <ButtonTypeRoll type="video" className="option_button_type tag-crea-historia-video" onClick={() => handleClick("video")} />
                    <h1>VIDEO</h1>
                  </div>
                  {isAudioCapable ? (
                    <div className="options">
                      <ButtonTypeRoll type="audio" className="option_button_type tag-crea-historia-audio" onClick={() => handleClick("audio")} />
                      <h1>AUDIO</h1>
                    </div>
                  ) : null}
                </ContainerOptions>
                {/* <ContainerInspiracion>
                  <div className="text-inspiracion">
                    <h2>¿Necesitas inspiración?</h2>
                    <p>Sigue estas recomendaciones para contar mejor tu Jeepstorie.</p>
                  </div>
                  <ButtonText onClick={handleModal} className="Button_Tips" themeType={ButtonTextType.light} classTag="tag-crea-historia-tips">
                    VIDEO TIPS
                  </ButtonText>
                </ContainerInspiracion> */}
              </ContainerInfo>
            </FormOptionsStage>
          </Col>
        </Row>
      </Container>
      {isOpen && <VideoPopUp isOpen={isOpen} setIsOpen={setIsOpen} />}
    </FormOptionsContainer>
  )
}

export default FormOptions
