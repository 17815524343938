import * as React from "react"

function SvgEdit({ width = "20", height = "18.59", className = "" }) {
  return (
    <svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 18.59">
      <path d="M0 14.67v3.93h3.8l11-11.47L11 3.2 0 14.67zM17.7 4.13c.4-.41.4-1.03 0-1.45L15.4.3a.95.95 0 00-1.4 0l-1.8 1.86L16 6.09l1.7-1.96zM9 16.53L7 18.6h13v-2.07H9z" />
    </svg>
  )
}

export default SvgEdit
