import * as React from "react"

function SvgLink({ width='20', height = '20', className = '' }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      xmlSpace="preserve"
    >
      <path
        d="M2.2 11.3c-2.9 2.9-2.9 7.6 0 10.5C3.6 23.3 5.5 24 7.4 24c1.9 0 3.8-.7 5.3-2.2l2-2c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-2 2c-2.1 2.1-5.6 2.1-7.7 0-2.1-2.1-2.1-5.6 0-7.7l2-2c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-2 2zm7.1-7.1c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0l2-2c2.1-2.1 5.6-2.1 7.7 0 2.1 2.1 2.1 5.6 0 7.7l-2 2c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l2-2c2.9-2.9 2.9-7.6 0-10.5-2.9-2.9-7.6-2.9-10.5 0l-2 2z"
      />
      <path
        d="M8.7 15.3c.2.2.5.3.7.3.3 0 .5-.1.7-.3l5.1-5.1c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-5.1 5.1c-.4.3-.4 1 0 1.4z"
      />
    </svg>
  )
}

export default SvgLink;