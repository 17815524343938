import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

const VideoContainer = styled.div`
  position: relative;
  /* padding-top: 56.25%; */
  margin: 50px 0 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .react-player {
    background-color: ${props => props.theme.vars.charade};
    box-shadow: 0px 54px 45px -56px rgba(0, 0, 0, 1);
  }

  .image-svg {
    color: ${props => props.theme.vars.white};
    height: 50px;
    left: 50%;
    position: absolute;
    pointer-events: none;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
  }

  @media ${device.sm} {
    margin: 70px 0 88px 0;

    .react-player {
      box-shadow: 0px 75px 71px -63px rgba(0, 0, 0, 1);
    }
    .image-svg {
      height: 70px;
      width: 70px;
    }
  }

  @media ${device.md} {
    top: -53px;
    margin: 0px 0 56px 0;

    .react-player {
      box-shadow: 0px 153px 122px -125px rgba(0, 0, 0, 1);
    }
    .image-svg {
      height: 100px;
      width: 100px;
    }
  }

  @media ${device.lg} {
    top: -63px;
  }

  @media ${device.xl} {
    top: -94px;
    margin: 0px 0 77px 0;
    .react-player {
      box-shadow: 0px 167px 144px -125px rgba(0, 0, 0, 1);
    }
  }
`

export default VideoContainer
