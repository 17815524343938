import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

export const UploadImageContainer = styled.div`
  position: relative;
  margin-bottom: 28px;
  .upload--hashtag {
    font-family: ${props => props.theme.vars.tradeGot};
    font-size: 14px;
    font-weight: ${props => props.theme.vars.fontBold};
    color: ${props => props.theme.vars.charade};
    letter-spacing: 1px;
    text-transform: uppercase;
    /* padding-left: 17px; */
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .upload-img--jeepLogo {
    width: 80px;
    height: auto;
    margin-right: 15px;
  }

  .upload--img__container {
    position: relative;
    width: 100%;
    height: 300px;
    /* background-color: ${props => props.theme.vars.charade}; */
    border: 1px dashed ${props => props.theme.vars.charade};
    border-radius: 15px;
    /* -webkit-box-shadow: 0px 2px 0px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 0px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 0px 1px rgba(0, 0, 0, 0.1); */
    &.is-white {
      background-color: ${props => props.theme.vars.white};
    }
    &.active-actions {
      &:before {
        content: "";
        position: absolute;
        top: 13px;
        left: 13px;
        width: calc(100% - 26px);
        height: calc(100% - 26px);
        background-color: ${props => props.theme.vars.black};
        opacity: 0.7;
      }
      .upload--button__edit {
        background-color: ${props => props.theme.vars.mustard};
        svg {
          /* fill: ${props => props.theme.vars.charade}; */
        }
      }
    }
  }
  .ulpload--img__input {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &.hide {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }
  }
  .upload-img {
    height: 100%;
    width: 100%;
    padding: 13px;
    object-fit: cover;
    object-position: center;
    box-sizing: border-box;
  }

  .upload--button__edit {
    position: absolute;
    right: -10px;
    top: 22px;
  }

  .input-file--container {
    margin-bottom: 15px;
  }

  .input-file--description {
    color: ${props => props.theme.vars.mirage};
    font-family: ${props => props.theme.vars.avenirNext};
    font-size: 14px;
    max-width: 200px;
    text-align: center;
    line-height: 20px;
  }

  /* Warning */
  .input-file--error {
    max-width: 282px;
    width: 100%;
    width: calc(100% - 10px);
  }
  /* Warning */

  @media ${device.sm} {
    .upload-img--jeepLogo {
      width: 100px;
    }
    .upload--img__container {
      height: 400px;
    }
    .upload--button__edit {
      right: -2px;
      top: 36px;
    }
  }
  @media ${device.md} {
    margin-bottom: 40px;
    .upload--hashtag {
      transform: translateY(-30px);
    }
    .upload--img__container {
      height: 502px;
      &.active-actions {
        &:before {
          top: 18px;
          left: 18px;
          width: calc(100% - 36px);
          height: calc(100% - 36px);
        }
      }
    }
    .upload-img {
      padding: 18px;
    }
    .input-file--description {
      max-width: 100%;
    }
    .upload--button__edit {
      right: -15px;
      top: 40px;
    }
  }
  @media ${device.lg} {
    margin-bottom: 70px;
    .upload--img__container {
      height: 582px;
      &.active-actions {
        &:before {
          top: 22px;
          left: 22px;
          width: calc(100% - 42px);
          height: calc(100% - 42px);
        }
      }
    }
    .upload-img {
      padding: 22px;
    }
    .upload--button__edit {
      right: -30px;
      top: 55px;
    }
  }
  @media ${device.xl} {
    margin-bottom: 120px;
    .upload--hashtag {
      margin-bottom: 28px;
    }
    .upload--img__container {
      height: 775px;
      &.active-actions {
        &:before {
          top: 30px;
          left: 30px;
          width: calc(100% - 60px);
          height: calc(100% - 60px);
        }
      }
    }
    .upload-img {
      padding: 30px;
    }
    .upload--button__edit {
      right: -24px;
      top: 50px;
    }
  }
`
export const UploadActions = styled.div`
  position: absolute;
  top: 42px;
  left: 100px;

  @media ${device.sm} {
    top: 55px;
    left: 125px;
  }

  @media ${device.md} {
    top: 68px;
    left: 175px;
  }

  @media ${device.lg} {
    top: 86px;
    left: 320px;
  }

  @media ${device.xl} {
    left: 470px;
  }
`
export const UploadAction = styled.div`
  font-family: ${props => props.theme.vars.tradeGot};
  font-size: 12px;
  font-weight: ${props => props.theme.vars.fontBold};
  color: ${props => props.theme.vars.white};
  letter-spacing: 2px;
  text-transform: uppercase;
  padding-bottom: 8px;
  border-bottom: 1px solid transparent;
  margin-bottom: 26px;
  text-align: right;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid ${props => props.theme.vars.mustard};
  }

  @media ${device.sm} {
    padding-bottom: 6px;
    margin-bottom: 14px;
  }
  @media ${device.md} {
    font-size: 14px;
  }
  @media ${device.xl} {
    font-size: 16px;
    margin-bottom: 35px;
  }
`
