import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

export const InputUrlContainer = styled.div`
  position: relative;
  height: 45px;
  width: 100%;
  background-color: ${props => props.theme.vars.silver};
  display: flex;

  @media ${device.sm} {
    height: 50px;
  }
  @media ${device.xl} {
    height: 60px;
  }
`
export const InputUrl = styled.div`
  position: relative;
  display: flex;
  /* width: calc(100% - 123px); */
  width: 100%;
  svg {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    height: 18px;
    width: 18px;
    fill: rgba(41, 41, 51, 0.7);
  }
  input {
    font-family: ${props => props.theme.vars.avenirNext};
    font-size: 16px;
    color: rgba(41, 41, 51, 0.7);
    background-color: transparent;
    width: 100%;
    height: 100%;
    border: none;
    padding: 0px 25px 0px 46px;
    &:hover,
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: rgba(41, 41, 51, 0.7);
    }
  }
  @media ${device.sm} {
    /* width: calc(100% - 200px); */
    input {
      font-size: 14px;
      padding: 0px 0px 0px 50px;
    }
  }
  @media ${device.md} {
    input {
      padding: 0px 0px 0px 60px;
    }
  }
  @media ${device.xl} {
    /* width: calc(100% - 250px); */
    input {
      font-size: 16px;
    }
  }
`
export const ButtonUrl = styled.button`
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 123px;
  height: 100%;
  font-family: ${props => props.theme.vars.tradeGot};
  font-size: 12px;
  font-weight: ${props => props.theme.vars.fontBold};
  color: ${props => props.theme.vars.white};
  background-color: ${props => props.theme.vars.charade};
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 0px;
  outline: none;
  border: none;
  cursor: pointer;
  display: none;
  @media ${device.sm} {
    width: 200px;
    font-size: 14px;
  }
  @media ${device.xl} {
    width: 250px;
  }
`
