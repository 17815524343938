import React from "react"

import SvgMicro from "../svgs/SvgMicro"
import SVGImage from "../svgs/SvgImage"
import SVGVideo from "../svgs/SvgVideo"

import ButtonTypeRollStyle from "./ButtonTypeRoll.style"

const ButtonTypeRoll = ({ className = "", type, onClick = null }) => {
  const getType = theType => {
    switch (theType) {
      case "camera":
        return <SVGImage />
      case "video":
        return <SVGVideo />
      case "audio":
        return <SvgMicro />
      default:
        return null
    }
  }

  return (
    <ButtonTypeRollStyle type="button" className={className} onClick={onClick}>
      <div className="o-option-bgroll" />
      <div className="o-option-bg" />
      <div className="o-option-content o-option-iconregular">{getType(type)}</div>
      <div className="o-option-content o-option-iconroll">{getType(type)}</div>
    </ButtonTypeRollStyle>
  )
}

export default ButtonTypeRoll
