import * as React from 'react';

function SvgMicro(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 36 36"
      xmlSpace="preserve"
      {...props}
    >
      <style>{'.svgmicro{fill:currentColor}'}</style>
      <path
        className="svgmicro"
        d="M18 25.71c4.11 0 7.71-3.6 7.71-7.71V7.71C25.71 3.34 22.11 0 18 0c-4.37 0-7.71 3.6-7.71 7.71V18c0 4.37 3.34 7.71 7.71 7.71z"
      />
      <path
        className="svgmicro"
        d="M30.86 14.71a1.291 1.291 0 00-2.58 0v4.58c0 4.89-4.63 9-9.77 9h-1.03c-5.14 0-9.77-4.11-9.77-9v-4.57c0-.71-.58-1.29-1.29-1.29s-1.28.57-1.28 1.28v4.57c0 5.66 4.63 10.54 10.29 11.31V36h5.14v-5.4c5.66-1.03 10.29-5.66 10.29-11.31v-4.58z"
      />
    </svg>
  );
}

export default SvgMicro;
