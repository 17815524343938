import React, { useState, useEffect } from "react"
// import NoiseWrapper from '../modules/wrappers/NoiseWrapper';
import FormAudio from "../modules/forms/formAudio/FormAudio"
import FormImage from "../modules/forms/formImage/FormImage"
import FormOptions from "../modules/forms/formOptions/FormOptions"
import FormVideo from "../modules/forms/formVideo/FormVideo"

const Forms = () => {
  const [option, setOption] = useState("options")

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0)
    }
  }, [option])

  const showTypeForm = type => {
    switch (type) {
      case "audio":
        return <FormAudio option={option} setOption={setOption} />
      case "imagen":
        return <FormImage option={option} setOption={setOption} />
      case "video":
        return <FormVideo option={option} setOption={setOption} />
      case "options":
        return <FormOptions setOption={setOption} />
      default:
        return null
    }
  }

  return <div>{option && showTypeForm(option)}</div>
}

export default Forms
