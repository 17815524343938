import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

const UploadImagesContainer = styled.div`
  position: relative;
  z-index: 1;

  .upload--images__info {
    font-family: ${props => props.theme.vars.avenirNext};
    text-align: center;
    font-size: 14px;
    color: ${props => props.theme.vars.tuna};
    max-width: 220px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .upload--images__button {
    font-family: ${props => props.theme.vars.avenirNext};
  }

  @media ${device.sm} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .upload--images__indications {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .upload--images__item {
      width: calc(50% - 12px);
      &.marginTop {
        margin-top: 55px;
      }
    }
    .upload--images__button {
      /* width: calc(50% - 12px); */
      /* margin-top: 170px; */
    }
  }

  @media ${device.md} {
    .upload--images__item {
      width: 400px;
    }
    /* .upload--images__button{
      width: 400px;
      margin-top: 0px;
    } */

    .upload--images__info {
      max-width: 100%;
    }

    .upload--images__indications {
      width: 400px;
    }
  }
  @media ${device.lg} {
    .upload--images__item {
      width: 100%;
    }
    .upload--images__indications {
      width: 100%;
    }
    /* .upload--images__button{
      width: 100%;
    } */
  }
`
export default UploadImagesContainer
