import * as React from "react"

function SvgVideo(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" {...props}>
      <path d="M26.4 14.2V7.6c0-1.1-.8-1.9-1.9-1.9H1.9C.8 5.7 0 6.4 0 7.6v18.9c0 1.1.8 1.9 1.9 1.9h22.7c1.1 0 1.9-.8 1.9-1.9v-6.6l7.6 7.6V6.6l-7.7 7.6z" fill="currentColor" />
    </svg>
  )
}

export default SvgVideo
