import React from "react"
import SvgEdit from "../svgs/SvgEdit"

import ButtonEditContainer from "./ButtonsEdit.style"

const ButtonEdit = ({ className = "", activeActions, setActiveActions }) => {
  const classNameID = "o-button-edit"
  const handleActiveActions = () => {
    setActiveActions(!activeActions)
  }
  return (
    <ButtonEditContainer className={className} onClick={handleActiveActions}>
      <div className={`${classNameID}__bgnormal ${classNameID}__bg`} />
      <div className={`${classNameID}__bgroll ${classNameID}__bg`} />
      <SvgEdit className={`${classNameID}__svgnormal ${classNameID}__svg`} />
      <SvgEdit className={`${classNameID}__svgroll ${classNameID}__svg`} />
    </ButtonEditContainer>
  )
}

export default ButtonEdit
