import React, { useState } from "react"
import UploadImage from "../uploadImage/UploadImage"
import ButtonAddImage from "../../elements/buttonAddImage/ButtonAddImage"

import UploadImagesContainer from "./UploadImages.style"

const UploadImages = ({ className = "", images, setImages, refImages }) => {
  const [count, setCount] = useState(1)
  const [enablesImages, setEnablesImages] = useState([{ id: 1 }])

  return (
    <UploadImagesContainer className={className}>
      {enablesImages.map(item => {
        return <UploadImage key={item.id} id={item.id} className="upload--images__item" enablesImages={enablesImages} setEnablesImages={setEnablesImages} isFirst={item.id === 1} images={images} setImages={setImages} inputFileRef={refImages} requiredMessage="Debes cargar al menos una imagen" />
      })}
      <div className="upload--images__indications">
        <p className="upload--images__info">Añade de 1 hasta 4 imágenes para contar tu historia</p>
        {enablesImages.length !== 4 && images.length > 0 && <ButtonAddImage className="upload--images__button tag-formulario-imagen-agregar-imagen" count={count} setCount={setCount} setEnablesImages={setEnablesImages} />}
      </div>
    </UploadImagesContainer>
  )
}

export default UploadImages
