import React from "react"
import Forms from "../components/template/Forms"

const UploadYourStory = () => {
  return (
    <div className="wrapper">
      <Forms />
    </div>
  )
}

export default UploadYourStory
